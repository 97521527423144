import "./App.css"
import {Route, HashRouter, Routes} from "react-router-dom"

import NZ from "./pages/NZ"

const App = () => {

    return (
        <HashRouter>
            <Routes>
                <Route path="/nz/:uid/" element={<NZ/>}/>
            </Routes>
        </HashRouter>
    );
}

export default App;
