import React, {useState, useEffect} from "react"
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    useParams
} from "react-router-dom"
import axios from "axios"


const NZ = () => {
    const uid = useParams()?.uid
    console.log(uid)
    // fetch the information from the backend
    // Initialize state for each form field
    const [formData, setFormData] = useState({
        taobao_id: '',
        surname_pinyin: '',
        given_name_pinyin: '',
        gender: '', // 'M' or 'F'
        passport_number: '',
        passport_expiry_date: '',
        date_of_birth: '',
        identification_type: '', // 'birth_certificate', 'driver_license', or 'id_card'
        identification_number: '',
        issue_date: '',
        expiry_date: '',
        district: '',
        street: '',
        city: '',
        has_been_to_nz: false, // true or false
        date_of_last_visit_nz: '', // Date or null
        mobile_number: '',
        email: '',
        quota_wanted: '1', // 1 or 0
        payment_card_used: 'no', // 'yes' or 'no'
        payment_card_type: '', // 'visa' or 'mastercard'
        payment_card_number: '',
        payment_card_expiry_month: '',
        payment_card_expiry_year: '',
        payment_card_cvv: '',
        payment_cardholder_name: '',
        backup_payment_option: 'no', // 'yes' or 'no'
        backup_card_type: '', // 'visa' or 'mastercard'
        backup_card_number: '',
        backup_card_expiry_month: '',
        backup_card_expiry_year: '',
        backup_card_cvv: '',
        backup_cardholder_name: '',
        payment_failed_5_times: 'continue'
    });

    const [errors, setErrors] = useState({});  // <-- State to track validation errors
    const validateForm = () => {
        let formIsValid = true;
        let errors = {};

        // TaoBao ID Validation
        if (!formData.taobao_id) {
            formIsValid = false;
            errors['taobao_id'] = '淘宝号不能为空';
        }

        // Surname Pinyin Validation
        if (!formData.surname_pinyin) {
            formIsValid = false;
            errors['surname_pinyin'] = '姓(拼音)不能为空';
        }

        if (typeof formData.surname_pinyin !== "undefined") {
            if (!formData.surname_pinyin.match(/^[a-zA-Z]+$/)) {
                formIsValid = false;
                errors["surname_pinyin"] = "只能输入英文字母，是拼音，不是中文";
            }
        }

        // Given Name Pinyin Validation
        if (!formData.given_name_pinyin) {
            formIsValid = false;
            errors['given_name_pinyin'] = '名(拼音)不能为空';
        }

        if (typeof formData.given_name_pinyin !== "undefined") {
            if (!formData.given_name_pinyin.match(/^[a-zA-Z]+$/)) {
                formIsValid = false;
                errors["given_name_pinyin"] = "只能输入英文字母，是拼音，不是中文";
            }
        }

        // gender
        if (!formData.gender) {
            formIsValid = false;
            errors["gender"] = "性别需要选一个";
        }

        // Passport Number Validation
        if (!formData.passport_number) {
            formIsValid = false;
            errors['passport_number'] = '护照号码不能为空';
        }

        if (typeof formData.passport_number !== "undefined") {
            if (!formData.passport_number.match(/^[a-zA-Z0-9]+$/)) {
                formIsValid = false;
                errors["passport_number"] = "护照号码只能输入英文字母和数字";
            }
            // 只能九位
            if (formData.passport_number.length !== 9) {
                formIsValid = false;
                errors["passport_number"] = "护照号码只能为9位";
            }
        }

        // Passport Expiry Date Validation
        if (!formData.passport_expiry_date) {
            formIsValid = false;
            errors['passport_expiry_date'] = '护照过期日期不能为空';


        }
        // cannot be earlier than today + 6 months
        if (typeof formData.passport_expiry_date !== "undefined") {
            const today = new Date();
            const todayPlus6Months = new Date(today.getFullYear(), today.getMonth() + 6, today.getDate());
            const passportExpiryDate = new Date(formData.passport_expiry_date);

            if (passportExpiryDate < todayPlus6Months) {
                formIsValid = false;
                errors["passport_expiry_date"] = "护照过期日期不能早于开放时间后六个月";
            }
        }

        // Date of Birth Validation
        if (!formData.date_of_birth) {
            formIsValid = false;
            errors['date_of_birth'] = '出生日期不能为空';
        }
        // date of birth should not be earlier than today - 31 years
        if (typeof formData.date_of_birth !== "undefined") {
            const today = new Date();
            const todayMinus31Years = new Date(today.getFullYear() - 31, today.getMonth(), today.getDate());
            const dateOfBirth = new Date(formData.date_of_birth);

            if (dateOfBirth < todayMinus31Years) {
                formIsValid = false;
                errors["date_of_birth"] = "年龄不能超过31岁";
            }
        }

        // Identification Type Validation
        if (!formData.identification_type) {
            formIsValid = false;
            errors['identification_type'] = '身份信息类别不能为空';
        }

        // Identification Number Validation
        if (!formData.identification_number) {
            formIsValid = false;
            errors['identification_number'] = '证件号码不能为空';
        }

        // if identification type is id_card, then the length should be 18
        if (typeof formData.identification_number !== "undefined") {
            if (formData.identification_type === 'id_card' && formData.identification_number.length !== 18) {
                formIsValid = false;
                errors["identification_number"] = "身份证号码只能为18位";
            }
        }

        // identification issue date
        if (!formData.issue_date) {
            formIsValid = false;
            errors['issue_date'] = '证件开始日期不能为空';
        }

        // identification expiry date
        if (!formData.expiry_date) {
            formIsValid = false;
            errors['expiry_date'] = '证件截至日期不能为空';
        }

        // identification expiry date should be later than issue date
        if (typeof formData.issue_date !== "undefined" && typeof formData.expiry_date !== "undefined") {
            const issueDate = new Date(formData.issue_date);
            const expiryDate = new Date(formData.expiry_date);
            if (expiryDate < issueDate) {
                formIsValid = false;
                errors["expiry_date"] = "证件截至日期不能早于开始日期";
            }
        }

        // District Validation
        if (!formData.district) {
            formIsValid = false;
            errors['district'] = '区不能为空';
        }
        // can not be longer than 20 characters
        if (typeof formData.district !== "undefined") {
            if (formData.district.length > 20) {
                formIsValid = false;
                errors["district"] = "区的长度不能超过20，写简短点";
            }
            // must be all English letters
            if (!formData.district.match(/^[a-zA-Z]+$/)) {
                formIsValid = false;
                errors["district"] = "区只能为英文字母，写拼音，别写中文";
            }
        }

        // Street Validation
        if (!formData.street) {
            formIsValid = false;
            errors['street'] = '街道不能为空';
        }
        // can not be longer than 40 characters
        if (typeof formData.street !== "undefined") {
            if (formData.street.length > 40) {
                formIsValid = false;
                errors["street"] = "街道长度不能超过40个字符，写简短点";
            }

            // must be all English letters
            if (!formData.street.match(/^[a-zA-Z]+$/)) {
                formIsValid = false;
                errors["street"] = "街道只能为英文字母，不能为中文，写拼音可以";
            }
        }

        // City Validation
        if (!formData.city) {
            formIsValid = false;
            errors['city'] = '城市要填';
        }
        // can not be longer than 20 characters
        if (typeof formData.city !== "undefined") {
            if (formData.city.length > 20) {
                formIsValid = false;
                errors["city"] = "城市不能超过20个字符，写简短点";
            }
            // must be all English letters
            if (!formData.city.match(/^[a-zA-Z]+$/)) {
                formIsValid = false;
                errors["city"] = "城市只能为英文字母，不能是中文，写拼音";
            }
        }

        // Has Been to New Zealand Validation
        if (!formData.has_been_to_nz) {
            formIsValid = false;
            errors['has_been_to_nz'] = '是否去过新西兰不能为空';
        }

// Date of Last Visit to New Zealand Validation if has_been_to_nz is true
        if (formData.has_been_to_nz === "Yes" && !formData.date_of_last_visit_nz) {
            formIsValid = false;
            errors['date_of_last_visit_nz'] = '曾经前往新西兰日期不能为空';
        }

        // Mobile Number Validation
        if (!formData.mobile_number) {
            formIsValid = false;
            errors['mobile_number'] = '手机号码不能为空';
        }
        // can not be longer than 11 characters
        if (typeof formData.mobile_number !== "undefined") {
            if (formData.mobile_number.length !== 11) {
                formIsValid = false;
                errors["mobile_number"] = "手机号码只能为11位";
            }
        }

        // Email Validation
        if (!formData.email) {
            formIsValid = false;
            errors['email'] = '邮箱不能为空';
        }

        // confirm it is a valid email address
        if (typeof formData.email !== "undefined") {
            let lastAtPos = formData.email.lastIndexOf('@');
            let lastDotPos = formData.email.lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && formData.email.indexOf('@@') === -1 && lastDotPos > 2 && (formData.email.length - lastDotPos) > 2)) {
                formIsValid = false;
                errors["email"] = "输入正确的邮箱地址";
            }
        }

        // Quota Wanted Validation
        if (!formData.quota_wanted) {
            formIsValid = false;
            errors['quota_wanted'] = '是否要拿名额不能为空';
        }
        //
        // // Payment Card Used Validation
        // if (!formData.payment_card_used) {
        //     formIsValid = false;
        //     errors['payment_card_used'] = '是否使用自己的卡扣除新西兰申请费不能为空';
        // }
        //
        // // Payment Card Type Validation
        // if (formData.payment_card_used === 'yes' && !formData.payment_card_type) {
        //     formIsValid = false;
        //     errors['payment_card_type'] = '卡类别不能为空';
        // }
        //
        // // Payment Card Number Validation
        // if (formData.payment_card_used === 'yes' && !formData.payment_card_number) {
        //     formIsValid = false;
        //     errors['payment_card_number'] = '扣款卡号不能为空';
        // }
        //
        // // Payment Card Number Validation MUST BE digits
        // if (formData.payment_card_used === 'yes' && typeof formData.payment_card_number !== "undefined") {
        //     if (!formData.payment_card_number.match(/^[0-9]+$/)) {
        //         formIsValid = false;
        //         errors["payment_card_number"] = "只能输入数字";
        //     }
        // }
        //
        // // Payment Card Expiry Month Validation
        // if (formData.payment_card_used === 'yes' && !formData.payment_card_expiry_month) {
        //     formIsValid = false;
        //     errors['payment_card_expiry_month'] = '卡过期月份不能为空';
        // }
        //
        // // Payment Card Expiry Month Validation MUST BE digits and only 2 digits
        // if (formData.payment_card_used === 'yes' && typeof formData.payment_card_expiry_month !== "undefined") {
        //     if (!formData.payment_card_expiry_month.match(/^[0-9]+$/)) {
        //         formIsValid = false;
        //         errors["payment_card_expiry_month"] = "只能输入数字";
        //     }
        //     if (formData.payment_card_expiry_month.length !== 2) {
        //         formIsValid = false;
        //         errors["payment_card_expiry_month"] = "卡过期月份只能为2位";
        //     }
        // }
        // // Payment Card Expiry Year Validation
        // if (formData.payment_card_used === 'yes' && !formData.payment_card_expiry_year) {
        //     formIsValid = false;
        //     errors['payment_card_expiry_year'] = '卡过期年份不能为空';
        // }
        //
        // // Payment Card Expiry Year Validation MUST BE digits and only 2 digits
        // if (formData.payment_card_used === 'yes' && typeof formData.payment_card_expiry_year !== "undefined") {
        //     if (!formData.payment_card_expiry_year.match(/^[0-9]+$/)) {
        //         formIsValid = false;
        //         errors["payment_card_expiry_year"] = "只能输入数字";
        //     }
        //     if (formData.payment_card_expiry_year.length !== 2) {
        //         formIsValid = false;
        //         errors["payment_card_expiry_year"] = "卡过期年份只能为2位";
        //     }
        // }
        //
        // // Payment Card CVV Validation
        // if (formData.payment_card_used === 'yes' && !formData.payment_card_cvv) {
        //     formIsValid = false;
        //     errors['payment_card_cvv'] = '安全码不能为空';
        // }
        //
        // // Payment Card CVV Validation MUST BE digits and only 3 digits
        // if (formData.payment_card_used === 'yes' && typeof formData.payment_card_cvv !== "undefined") {
        //     if (!formData.payment_card_cvv.match(/^[0-9]+$/)) {
        //         formIsValid = false;
        //         errors["payment_card_cvv"] = "只能输入数字";
        //     }
        //     if (formData.payment_card_cvv.length !== 3) {
        //         formIsValid = false;
        //         errors["payment_card_cvv"] = "安全码只能为3位";
        //     }
        // }
        //
        // // Payment Cardholder Name Validation
        // if (formData.payment_card_used === 'yes' && !formData.payment_cardholder_name) {
        //     formIsValid = false;
        //     errors['payment_cardholder_name'] = '持卡人姓名不能为空';
        // }
        //
        // // Backup Payment Option Validation
        // if (formData.payment_card_used === 'yes' && !formData.backup_payment_option) {
        //     formIsValid = false;
        //     errors['backup_payment_option'] = '是否要启用第二张备卡以增加付款成功率不能为空';
        // }
        //
        // // Backup Card Type Validation
        // if (formData.payment_card_used === 'yes' && formData.backup_payment_option === 'yes' && !formData.backup_card_type) {
        //     formIsValid = false;
        //     errors['backup_card_type'] = '卡类别不能为空';
        // }
        //
        // // Backup Card Number Validation
        // if (formData.payment_card_used === 'yes' && formData.backup_payment_option === 'yes' && !formData.backup_card_number) {
        //     formIsValid = false;
        //     errors['backup_card_number'] = '扣款卡号不能为空';
        //
        // }
        // // Backup Card Number Validation MUST BE digits
        // if (formData.payment_card_used === 'yes' && formData.backup_payment_option === 'yes' && typeof formData.backup_card_number !== "undefined") {
        //     if (!formData.backup_card_number.match(/^[0-9]+$/)) {
        //         formIsValid = false;
        //         errors["backup_card_number"] = "只能输入数字";
        //     }
        // }
        //
        // // Backup Card Expiry Month Validation
        // if (formData.payment_card_used === 'yes' && formData.backup_payment_option === 'yes' && !formData.backup_card_expiry_month) {
        //     formIsValid = false;
        //     errors['backup_card_expiry_month'] = '卡过期月份不能为空';
        // }
        //
        // // Backup Card Expiry Month Validation MUST BE digits and only 2 digits
        // if (formData.payment_card_used === 'yes' && formData.backup_payment_option === 'yes' && typeof formData.backup_card_expiry_month !== "undefined") {
        //     if (!formData.backup_card_expiry_month.match(/^[0-9]+$/)) {
        //         formIsValid = false;
        //         errors["backup_card_expiry_month"] = "只能输入数字";
        //     }
        //     if (formData.backup_card_expiry_month.length !== 2) {
        //         formIsValid = false;
        //         errors["backup_card_expiry_month"] = "卡过期月份只能为2位";
        //     }
        // }
        //
        // // Backup Card Expiry Year Validation
        // if (formData.payment_card_used === 'yes' && formData.backup_payment_option === 'yes' && !formData.backup_card_expiry_year) {
        //     formIsValid = false;
        //     errors['backup_card_expiry_year'] = '卡过期年份不能为空';
        // }
        //
        // // Backup Card Expiry Year Validation MUST BE digits and only 2 digits
        // if (formData.payment_card_used === 'yes' && formData.backup_payment_option === 'yes' && typeof formData.backup_card_expiry_year !== "undefined") {
        //     if (!formData.backup_card_expiry_year.match(/^[0-9]+$/)) {
        //         formIsValid = false;
        //         errors["backup_card_expiry_year"] = "只能输入数字";
        //     }
        //     if (formData.backup_card_expiry_year.length !== 2) {
        //         formIsValid = false;
        //         errors["backup_card_expiry_year"] = "卡过期年份只能为2位";
        //     }
        // }
        //
        // // Backup Card CVV Validation
        // if (formData.payment_card_used === 'yes' && formData.backup_payment_option === 'yes' && !formData.backup_card_cvv) {
        //     formIsValid = false;
        //     errors['backup_card_cvv'] = '安全码不能为空';
        // }
        //
        // // Backup Card CVV Validation MUST BE digits and only 3 digits
        // if (formData.payment_card_used === 'yes' && formData.backup_payment_option === 'yes' && typeof formData.backup_card_cvv !== "undefined") {
        //     if (!formData.backup_card_cvv.match(/^[0-9]+$/)) {
        //         formIsValid = false;
        //         errors["backup_card_cvv"] = "只能输入数字";
        //     }
        //     if (formData.backup_card_cvv.length !== 3) {
        //         formIsValid = false;
        //         errors["backup_card_cvv"] = "安全码只能为3位";
        //     }
        // }
        //
        // // Backup Cardholder Name Validation
        // if (formData.payment_card_used === 'yes' && formData.backup_payment_option === 'yes' && !formData.backup_cardholder_name) {
        //     formIsValid = false;
        //     errors['backup_cardholder_name'] = '持卡人姓名不能为空';
        // }

        // Other validation logic here...

        setErrors(errors);
        console.log(errors)
        return formIsValid;
    };

    useEffect(() => {
        const convertNullToEmptyString = (obj) => {
            Object.keys(obj).forEach(key => {
                if (obj[key] && typeof obj[key] === 'object') {
                    convertNullToEmptyString(obj[key]);  // Recursive call for nested objects
                } else if (obj[key] === null) {
                    obj[key] = '';  // Convert null to empty string
                }
            });
            return obj;
        }

        //     call backend to get the data for the uid and set the form data
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/whvtmall/applicant/${uid}/`).then(res => {
            if (res.status === 200) {
                const copiedJsonObject = JSON.parse(JSON.stringify(res.data));
                setFormData(
                    convertNullToEmptyString(copiedJsonObject)
                )
                console.log(convertNullToEmptyString(copiedJsonObject))
            } else {
                console.log(res.data)
            }
        })

    }, [uid])

    // Handle form input changes
    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setFormData({...formData, [name]: value});
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!validateForm()) {
            console.error('Validation failed');
            return;
        }
        // Convert formData to match Django model

        if (formData.has_been_to_nz === "No") {
            formData.date_of_last_visit_nz = null;
        }

        const submissionData = {
            ...formData,
        };

        console.log(submissionData);
        axios.put(
            `${process.env.REACT_APP_BACKEND_URL}/whvtmall/applicant/update/${uid}/`,
            submissionData
        ).then(res => {
            if (res.status === 200) {
                alert('更新成功，可以刷新该页面确认')
            }
        })
        // Submit `submissionData` to your backend
    };


    if (!uid) return <div>链接无效</div>

    return (
        <div className="container mt-5">
            <form onSubmit={handleSubmit}>
                {/* TaoBao ID */}
                <div className="mb-3">
                    <label htmlFor="applicantTaobaoID" className="form-label">申请人的淘宝号</label>
                    <input
                        type="text"
                        className="form-control"
                        id="taobao_id"
                        name="taobao_id"
                        value={formData.taobao_id}
                        onChange={handleInputChange}
                        placeholder="正在获取订单"
                        disabled
                    />
                </div>

                {/* Surname (Pinyin) */}
                <div className="mb-3">
                    <label htmlFor="surname_pinyin" className="form-label">姓(拼音)，也就是Surname</label>
                    <input
                        type="text"
                        className="form-control"
                        id="surname_pinyin"
                        name="surname_pinyin"
                        value={formData.surname_pinyin}
                        onChange={handleInputChange}
                        placeholder="姓氏（拼音）不要写中文"
                    />
                    <div className="text-danger">{errors.surname_pinyin}</div>
                    {/* Validation message */}
                </div>

                {/* Given Name (Pinyin) */}
                <div className="mb-3">
                    <label htmlFor="given_name_pinyin" className="form-label">名(拼音)，也就是Given name</label>
                    <input
                        type="text"
                        className="form-control"
                        id="given_name_pinyin"
                        name="given_name_pinyin"
                        value={formData.given_name_pinyin}
                        onChange={handleInputChange}
                        placeholder="名字拼音，不要写中文，张三的话，这里填SAN"
                    />
                    <div className="text-danger">{errors.given_name_pinyin}</div>
                </div>

                {/* Gender */}
                <div className="mb-3">
                    <label>性别</label>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="gender"
                            id="genderMale"
                            value="M"
                            checked={formData.gender === "M"}
                            onChange={handleInputChange}
                        />
                        <label className="form-check-label" htmlFor="genderMale">男</label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="gender"
                            id="genderFemale"
                            value="F"
                            checked={formData.gender === "F"}
                            onChange={handleInputChange}
                        />
                        <label className="form-check-label" htmlFor="genderFemale">女</label>
                    </div>
                    <div className="text-danger">{errors.gender}</div>
                </div>

                {/* Passport Number */}
                <div className="mb-3">
                    <label htmlFor="passport_number"
                           className="form-label">护照号码</label>
                    <input
                        type="text"
                        className="form-control"
                        id="passport_number"
                        name="passport_number"
                        value={formData.passport_number}
                        onChange={handleInputChange}
                        placeholder="申请人护照一般为G或者E开头，使用大写字母，请仔细检查，尤其是那个1和I的区别"
                    />

                    <div className="text-danger">{errors.passport_number}</div>
                </div>

                {/* Passport Expiry Date */}
                <div className="mb-3">
                    <label htmlFor="passport_expiry_date" className="form-label">护照过期日期</label>
                    <input
                        type="date"
                        className="form-control"
                        id="passport_expiry_date"
                        name="passport_expiry_date"
                        value={formData.passport_expiry_date}
                        onChange={handleInputChange}
                    />
                    <div className="text-danger">{errors.passport_expiry_date}</div>
                </div>
                <div className="mb-3">
                    <label htmlFor="date_of_birth" className="form-label">出生日期</label>
                    <input
                        type="date"
                        className="form-control"
                        id="date_of_birth"
                        name="date_of_birth"
                        value={formData.date_of_birth}
                        onChange={handleInputChange}
                    />
                    <div className="text-danger">{errors.date_of_birth}</div>
                </div>


                {/* Identification Type */}
                <div className="mb-3">
                    <label htmlFor="identification_type"
                           className="form-label">申请人身份信息类别（基本都是选身份证）</label>
                    <select
                        className="form-select"
                        id="identification_type"
                        name="identification_type"
                        value={formData.identification_type}
                        onChange={handleInputChange}
                    >
                        <option value="">请选择</option>
                        <option value="birth_certificate">出生证明</option>
                        <option value="driver_license">驾照</option>
                        <option value="id_card">身份证</option>
                        {/* ... other options ... */}
                    </select>
                    <div className="text-danger">{errors.identification_type}</div>
                </div>

                {/* Identification Number */}
                <div className="mb-3">
                    <label htmlFor="identification_number"
                           className="form-label">证件号码(驾照和身份证号码是一样的)</label>
                    <input
                        type="text"
                        className="form-control"
                        id="identification_number"
                        name="identification_number"
                        value={formData.identification_number}
                        onChange={handleInputChange}
                        placeholder="请正确填写证件号码"
                    />
                    <div className="text-danger">{errors.identification_number}</div>
                </div>
                <div className="mb-3">
                    <label htmlFor="issue_date" className="form-label">证件有效期开始日期</label>
                    <input
                        type="date"
                        className="form-control"
                        id="issue_date"
                        name="issue_date"
                        value={formData.issue_date}
                        onChange={handleInputChange}
                    />
                    <div className="text-danger">{errors.issue_date}</div>
                </div>
                <div className="mb-3">
                    <label htmlFor="expiry_date" className="form-label">证件有效期截至日期</label>
                    <input
                        type="date"
                        className="form-control"
                        id="expiry_date"
                        name="expiry_date"
                        value={formData.expiry_date}
                        onChange={handleInputChange}
                    />
                    <div className="text-danger">{errors.expiry_date}</div>
                </div>
                {/* District */}
                <div className="mb-3">
                    <label htmlFor="district" className="form-label">区(使用英文字母，不能超过20个字符)</label>
                    <input
                        type="text"
                        className="form-control"
                        id="district"
                        name="district"
                        value={formData.district}
                        onChange={handleInputChange}
                        placeholder="目前居住城市的区，比如说CHAOYANG"
                    />
                    <div className="text-danger">{errors.district}</div>
                </div>
                {/* District */}
                <div className="mb-3">
                    <label htmlFor="street" className="form-label">街道(使用英文字母,不能超过40个字符)</label>
                    <input
                        type="text"
                        className="form-control"
                        id="street"
                        name="street"
                        value={formData.street}
                        onChange={handleInputChange}
                        placeholder="目前居住的街道，用拼音，不用特别准确，比如说DONGZHIMEN"
                    />
                    <div className="text-danger">{errors.street}</div>
                </div>
                {/* City */}
                <div className="mb-3">
                    <label htmlFor="city" className="form-label">城市(使用英文字母，不能超过20个字符)</label>
                    <input
                        type="text"
                        className="form-control"
                        id="city"
                        name="city"
                        value={formData.city}
                        onChange={handleInputChange}
                        placeholder="目前居住的城市，比如说BEIJING"
                    />
                    <div className="text-danger">{errors.city}</div>
                </div>
                {/* Has Been to New Zealand */}
                <div className="mb-3">
                    <label>曾经去过新西兰</label>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="has_been_to_nz"
                            id="hasBeenToNZYes"
                            value="Yes"
                            checked={formData.has_been_to_nz === "Yes"}
                            onChange={handleInputChange}
                        />
                        <label className="form-check-label" htmlFor="hasBeenToNZYes">
                            是
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="has_been_to_nz"
                            id="hasBeenToNZNo"
                            value="No"
                            checked={formData.has_been_to_nz === "No"}
                            onChange={handleInputChange}
                        />
                        <label className="form-check-label" htmlFor="hasBeenToNZNo">
                            否
                        </label>
                    </div>
                    <div className="text-danger">{errors.has_been_to_nz}</div>
                </div>
                {formData.has_been_to_nz === "Yes" && (
                    <div className="mb-3">
                        <label htmlFor="date_of_last_visit_nz" className="form-label">
                            如果曾经去过新西兰，什么时候入境新西兰的
                        </label>
                        <input
                            type="date"
                            className="form-control"
                            id="date_of_last_visit_nz"
                            name="date_of_last_visit_nz"
                            value={formData.date_of_last_visit_nz}
                            onChange={handleInputChange}
                        />
                        <div className="text-danger">{errors.date_of_last_visit_nz}</div>
                    </div>
                )}

                {/* Mobile Number */}
                <div className="mb-3">
                    <label htmlFor="mobile_number" className="form-label">手机号码（接收通知用）</label>
                    <input
                        type="tel"
                        className="form-control"
                        id="mobile_number"
                        name="mobile_number"
                        value={formData.mobile_number}
                        onChange={handleInputChange}
                        placeholder="请输入11位中国手机号码"
                    />
                    <div className="text-danger">{errors.mobile_number}</div>
                </div>

                {/* Email */}
                <div className="mb-3">
                    <label htmlFor="email" className="form-label">邮箱（接收通知用）</label>
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        placeholder="我们会用这个邮箱通知你重要信息更新，请务必填写正确"
                    />
                    <div className="text-danger">{errors.email}</div>
                </div>

                {/* Quota Wanted */}
                <div className="mb-3">
                    <label htmlFor="quota_wanted" className="form-label">是否要抢本次名额</label>
                    <select
                        className="form-select"
                        id="quota_wanted"
                        name="quota_wanted"
                        value={formData.quota_wanted}
                        onChange={handleInputChange}
                    >
                        <option value="">请选择</option>
                        <option value="1">抢这次</option>
                        <option value="0">下次吧</option>
                    </select>
                    <div className="text-danger">{errors.quota_wanted}</div>
                </div>

                {/*/!* Payment Card Used *!/*/}
                {/*<div className="mb-3">*/}
                {/*    <label htmlFor="payment_card_used" className="form-label">是否使用自己的卡扣除新西兰申请费</label>*/}
                {/*    <select*/}
                {/*        className="form-select"*/}
                {/*        id="payment_card_used"*/}
                {/*        name="payment_card_used"*/}
                {/*        value={formData.payment_card_used}*/}
                {/*        onChange={handleInputChange}*/}
                {/*    >*/}
                {/*        <option value="">请选择</option>*/}
                {/*        <option value="yes">是，使用自己的卡扣申请费(推荐)</option>*/}
                {/*        <option value="no">不，依旧用你们的卡帮我付，我自己承担由此导致的下签失败的风险</option>*/}
                {/*    </select>*/}
                {/*    <div className="text-danger">{errors.payment_card_used}</div>*/}
                {/*</div>*/}
                {/* Payment Type Selection */}
                {formData.payment_card_used === 'yes' &&
                    <>
                        {/*add a option 扣款失败5次以上，接下来将采取*/}
                        <div className="mb-3">
                            <div className="mb-3">
                                <label htmlFor="payment_failed_5_times"
                                       className="form-label">扣款失败5次以上，接下来将采取</label>
                                <select
                                    className="form-select"
                                    id="payment_failed_5_times"
                                    name="payment_failed_5_times"
                                    value={formData.payment_failed_5_times}
                                    onChange={handleInputChange}
                                >
                                    <option value="stop">不要再尝试帮我付钱了，直接停止就行</option>
                                    <option value="continue">请使用你们的卡帮我付签证费（推荐）</option>
                                    {/* Add other payment types if necessary */}
                                </select>
                            </div>
                            <div className="text-danger">{errors.payment_failed_5_times}</div>

                        </div>

                        <div className="mb-3">
                            <label htmlFor="payment_card_type" className="form-label">卡类别(请认真核实您手上的卡是VISA
                                CARD 还是 MASTERCARD，以免扣款失败)</label>
                            <select
                                className="form-select"
                                id="payment_card_type"
                                name="payment_card_type"
                                value={formData.payment_card_type}
                                onChange={handleInputChange}
                            >
                                <option value="">请选择</option>
                                <option value="visa">VISA</option>
                                <option value="mastercard">MASTERCARD</option>
                                {/* Add other payment types if necessary */}
                            </select>
                            <div className="text-danger">{errors.payment_card_type}</div>
                        </div>

                        {/* Payment Card Number */}
                        <div className="mb-3">
                            <label htmlFor="payment_card_number"
                                   className="form-label">扣款卡号(该卡号将通过系统直接提交给新西兰，由对方系统扣款申请费$455，保证额度充足)</label>
                            <input
                                type="text"
                                className="form-control"
                                id="payment_card_number"
                                name="payment_card_number"
                                value={formData.payment_card_number}
                                onChange={handleInputChange}
                                placeholder="信用卡号"
                                maxLength="19" // Credit card numbers are typically 16-19 digits long
                            />
                            <div className="text-danger">{errors.payment_card_number}</div>
                        </div>

                        {/* Card Expiry Month */}
                        <div className="mb-3">
                            <label htmlFor="payment_card_expiry_month"
                                   className="form-label">卡过期月份(卡正面卡号下方前面两个数字)</label>
                            <input
                                type="text"
                                className="form-control"
                                id="payment_card_expiry_month"
                                name="payment_card_expiry_month"
                                value={formData.payment_card_expiry_month}
                                onChange={handleInputChange}
                                placeholder="信用卡过期月份，如 10"
                                maxLength="2"
                            />
                            <div className="text-danger">{errors.payment_card_expiry_month}</div>
                        </div>

                        {/* Card Expiry Year */}
                        <div className="mb-3">
                            <label htmlFor="payment_card_expiry_year"
                                   className="form-label">卡过期年份(卡正面卡号下方后面两个数字)</label>
                            <input
                                type="text"
                                className="form-control"
                                id="payment_card_expiry_year"
                                name="payment_card_expiry_year"
                                value={formData.payment_card_expiry_year}
                                onChange={handleInputChange}
                                placeholder="信用卡过期年份，如24"
                                maxLength="2"
                            />
                            <div className="text-danger">{errors.payment_card_expiry_year}</div>
                        </div>

                        {/* Card CVV */}
                        <div className="mb-3">
                            <label htmlFor="payment_card_cvv" className="form-label">安全码(卡背面的三位数字)</label>
                            <input
                                type="text"
                                className="form-control"
                                id="payment_card_cvv"
                                name="payment_card_cvv"
                                value={formData.payment_card_cvv}
                                onChange={handleInputChange}
                                placeholder="如 333"
                                maxLength="3" // CVV is typically 3 or 4 digits
                            />
                            <div className="text-danger">{errors.payment_card_cvv}</div>
                        </div>

                        {/* Cardholder's Name */}
                        <div className="mb-3">
                            <label htmlFor="payment_cardholder_name"
                                   className="form-label">持卡人姓名(请填写英文字母，顺序也和卡上的顺序一样，如果姓和名中间有空格也需要填写空格)</label>
                            <input
                                type="text"
                                className="form-control"
                                id="payment_cardholder_name"
                                name="payment_cardholder_name"
                                value={formData.payment_cardholder_name}
                                onChange={handleInputChange}
                                placeholder="持卡人姓名，例如 ZHANG SAN"
                            />

                            <div className="text-danger">{errors.payment_cardholder_name}</div>
                        </div>

                        {/* Additional Payment Option */}
                        <div className="mb-3">
                            <label htmlFor="backup_payment_option"
                                   className="form-label">是否要启用第二张备卡以增加付款成功率</label>
                            <select
                                className="form-select"
                                id="backup_payment_option"
                                name="backup_payment_option"
                                value={formData.backup_payment_option}
                                onChange={handleInputChange}
                            >
                                <option value="">请选择</option>
                                <option value="yes">是的，启用第二张备卡（推荐）</option>
                                <option value="no">不使用备卡</option>
                            </select>
                            <div className="text-danger">{errors.backup_payment_option}</div>
                        </div>
                    </>
                }

                {
                    formData.payment_card_used === 'yes' && formData.backup_payment_option === 'yes' &&
                    <>
                        {/* Card Type for Backup Payment */}
                        <div className="mb-3">
                            <label htmlFor="backup_card_type" className="form-label">(2号卡)卡类别(请认真核实您手上的卡是VISA
                                Card 还是 MASTER Card，以免扣款失败)</label>
                            <select
                                className="form-select"
                                id="backup_card_type"
                                name="backup_card_type"
                                value={formData.backup_card_type}
                                onChange={handleInputChange}
                            >
                                <option value="">请选择</option>
                                <option value="visa">VISA</option>
                                <option value="mastercard">MASTERCARD</option>
                                {/* Add other card types if necessary */}
                            </select>
                            <div className="text-danger">{errors.backup_card_type}</div>
                        </div>

                        {/* Charge Amount */}
                        <div className="mb-3">
                            <label htmlFor="backup_card_number"
                                   className="form-label">(2号卡)扣款卡号,该卡号将通过系统直接提交给新西兰，由对方系统扣款申请费$455</label>
                            <input
                                type="text"
                                className="form-control"
                                id="backup_card_number"
                                name="backup_card_number"
                                value={formData.backup_card_number}
                                onChange={handleInputChange}
                                placeholder="申请费用金额"
                            />
                            <div className="text-danger">{errors.backup_card_number}</div>
                        </div>

                        {/* Card Expiry Month for Backup Payment */}
                        <div className="mb-3">
                            <label htmlFor="backup_card_expiry_month"
                                   className="form-label">(2号卡)卡过期月份,卡正面卡号下方前面两个数字</label>
                            <input
                                type="text"
                                className="form-control"
                                id="backup_card_expiry_month"
                                name="backup_card_expiry_month"
                                value={formData.backup_card_expiry_month}
                                onChange={handleInputChange}
                                placeholder="卡有效月份，例如 10"
                                maxLength="2"
                            />
                            <div className="text-danger">{errors.backup_card_expiry_month}</div>
                        </div>

                        {/* Card Expiry Year for Backup Payment */}
                        <div className="mb-3">
                            <label htmlFor="backup_card_expiry_year"
                                   className="form-label">(2号卡)卡过期年份,卡正面卡号下方后面两个数字</label>
                            <input
                                type="text"
                                className="form-control"
                                id="backup_card_expiry_year"
                                name="backup_card_expiry_year"
                                value={formData.backup_card_expiry_year}
                                onChange={handleInputChange}
                                placeholder="卡有效年份，例如 23"
                                maxLength="2"
                            />
                            <div className="text-danger">{errors.backup_card_expiry_year}</div>
                        </div>

                        {/* Card CVV for Backup Payment */}
                        <div className="mb-3">
                            <label htmlFor="backup_card_cvv"
                                   className="form-label">(2号卡)安全码，卡背面的三位数字</label>
                            <input
                                type="text"
                                className="form-control"
                                id="backup_card_cvv"
                                name="backup_card_cvv"
                                value={formData.backup_card_cvv}
                                onChange={handleInputChange}
                                placeholder="卡背面的安全码，例如388"
                                maxLength="3"
                            />
                            <div className="text-danger">{errors.backup_card_cvv}</div>
                        </div>

                        {/* Cardholder's Name for Backup Payment */}
                        <div className="mb-3">
                            <label htmlFor="backup_cardholder_name"
                                   className="form-label">(2号卡)持卡人姓名，请填写英文字母，顺序也和卡上的顺序一样，注意姓和名之间一般有空格</label>
                            <input
                                type="text"
                                className="form-control"
                                id="backup_cardholder_name"
                                name="backup_cardholder_name"
                                value={formData.backup_cardholder_name}
                                onChange={handleInputChange}
                                placeholder="持卡人全名，例如ZHANG SAN"
                            />
                            <div className="text-danger">{errors.backup_cardholder_name}</div>
                        </div>
                    </>
                }

                {/* ... Further fields as necessary ... */}
                {/* Cautionary Message */}
                <div className="alert alert-warning" role="alert">
                    请认真资料后再保存资料，以免提交到新西兰系统有误。
                </div>


                {/* Submit button */}
                <button type="submit" className="btn btn-primary">提交</button>
            </form>
        </div>
    )
}

export default NZ